import React, { memo, useCallback, useEffect, useState } from 'react';
import { useGetUserInfoQuery, useUpdateBillingInfoMutation } from 'common/store/userApi';
import { object, string } from 'yup';
import { BillingInfo } from '@cometph/frontend-core/api';
import { hasMessage, requiredError } from '@cometph/frontend-core/helpers';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import formStyles from 'styles/_form.module.scss';
import { TextField } from 'components/Field/TextField/TextField';
import styled from 'styled-components';
import { Button } from 'components/Button/Button';
import { styledColor, styledSpace } from 'styles/mixins';
import { useSnackbar } from 'notistack';
import { SelectField } from 'components/Field/SelectField';
import SearchIcon from '@mui/icons-material/Search';

type Schema = BillingInfo;

const schema = object().shape({
  bankName: string().required(requiredError).typeError(requiredError),
  accountNumber: string().required(requiredError).typeError(requiredError),
  accountName: string().required(requiredError).typeError(requiredError),
});

enum Bank {
  BDO = 'BDO',
  BPI = 'BPI',
  Metrobank = 'Metrobank',
  PNB = 'PNB',
  RCBC = 'RCBC',
  Unionbank = 'Unionbank',
}

const bankOptions = Object.values(Bank);

export const AppHeaderWithdrawBankDetails = memo(function AppHeaderWithdrawBankDetails() {
  const { data: { billingInfo } = {} } = useGetUserInfoQuery();
  const [updateBillingInfo] = useUpdateBillingInfoMutation();
  const form = useForm<Schema>({ resolver: yupResolver(schema), defaultValues: billingInfo });
  const [serverError, setServerError] = useState<string>();
  const resetForm = form.reset;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, [resetForm]);

  const saveBankDetails = useCallback(
    async (values: Schema) => {
      setServerError(undefined);

      try {
        await updateBillingInfo(values).unwrap();
        enqueueSnackbar('Your bank details have been successfully updated.', {
          variant: 'success',
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
      } catch (e) {
        if (hasMessage(e)) {
          setServerError(e.message);
        }
      }
    },
    [enqueueSnackbar, updateBillingInfo]
  );

  return (
    <>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(saveBankDetails)} noValidate>
          <SelectField options={bankOptions} label="Bank name *" name="bankName" startElement={<SelectIcon />} />
          <TextField name="accountNumber" label="Account number *" />
          <TextField name="accountName" label="Account name *" />
          <SubmitButton buttonStyle="primary" disabled={!form.formState.isDirty} type="submit" loading={form.formState.isSubmitting}>
            Save changes
          </SubmitButton>
          {!!serverError && <div className={formStyles.error}>{serverError}</div>}
        </Form>
      </FormProvider>
    </>
  );
});

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: ${styledSpace(6)};
  padding: ${styledSpace(2)} ${styledSpace(4)};
`;

const SelectIcon = styled(SearchIcon)`
  && {
    fill: ${styledColor('textDark')};
    font-size: ${styledSpace(6)};
    margin-top: ${styledSpace(1)};
    margin-left: -${styledSpace(1)};
  }
`;
