import React, { memo, ReactNode, useRef } from 'react';
import { FieldLabel } from 'components/Field/FieldLabel/FieldLabel';
import { Controller, useFormContext } from 'react-hook-form';
import { MenuItem, Select } from '@mui/material';
import formStyles from 'styles/_form.module.scss';
import { v4 as guid } from 'uuid';
import classNames from 'classnames';
import styled from 'styled-components';
import { styledColor, styledSpace } from 'styles/mixins';

type Option<T> = T | { value: T; label: ReactNode };

type Props<T extends string> = {
  name: string;
  label: string;
  endElement?: ReactNode;
  startElement?: ReactNode;
  options: Option<T>[];
};

function SelectFieldComponent<T extends string>({ name, label, endElement, startElement, options }: Props<T>) {
  const id = useRef(`${name}_${guid()}`);
  const methods = useFormContext();
  const error = methods.formState.errors[name]?.message?.toString();
  return (
    <Container
      className={classNames([
        formStyles.container,
        !!error && formStyles.hasError,
        !!endElement && formStyles.hasEndElement,
        !!startElement && formStyles.hasStartElement,
      ])}
    >
      <FieldLabel text={label} inputId={id.current} />
      <div className={formStyles.inputWrapper}>
        {!!startElement && <div className={formStyles.startElement}>{startElement}</div>}
        <Controller
          control={methods.control}
          name="bankName"
          render={({ field: { value, onChange } }) => (
            <Select
              className={formStyles.input}
              value={value ?? ''}
              onChange={(e) => onChange(e.target.value)}
              inputProps={{ id: id.current }}
            >
              {options.map((option) => {
                const value = typeof option === 'string' ? option : option.value;
                const label = typeof option === 'string' ? option : option.label;
                return (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        />
        {!!endElement && <div className={formStyles.endElement}>{endElement}</div>}
      </div>
      {!!error && <div className={formStyles.error}>{error}</div>}
    </Container>
  );
}

const Container = styled.div`
  .MuiSelect-icon {
    display: none;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${styledColor('borderDarker')} !important;
  }

  .MuiInputBase-input {
    padding: 0;
    outline: none !important;
    -moz-appearance: textfield;
  }

  .MuiInputBase-root {
    color: ${styledColor('text')};
    background-color: #292a41;
    padding: ${styledSpace(2)} ${styledSpace(3)};
  }
`;

export const SelectField = memo(SelectFieldComponent) as typeof SelectFieldComponent;
