import React, { MouseEvent, useCallback } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import './AppHeaderAccountButton.scss';
import { Button } from '../../../Button/Button';
import styles from './AppHeaderAccountButton.module.scss';
import { useMenuAnchor } from 'common/hooks/useMenuAnchor';
import { useAuth } from '@cometph/frontend-core/contexts';
import { AppHeaderLinkAaaAccountModal } from 'components/AppHeader/components/LinkAaaAccount/AppHeaderLinkAaaAccountModal';
import { useFlag } from '@cometph/frontend-core/hooks';
import { AppHeaderWithdraw } from 'components/AppHeader/components/Withdraw/AppHeaderWithdraw';
import { DepositModal } from 'components/DepositModal/DepositModal';

export const AppHeaderAccountButton = () => {
  const { anchorEl, setAnchorEl, isOpen } = useMenuAnchor();
  const { logout } = useAuth();
  const [isLinkAccountModalOpen, openLinkAccountModal, closeLinkAccountModal] = useFlag();
  const [isWithdrawModalOpen, openWithdrawModal, closeWithdrawModal] = useFlag();
  const [isDepositModalOpen, openDepositModal, closeDepositModal] = useFlag();

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <>
      <Button className={styles.button} onClick={handleClick}>
        My Account <ExpandMore className="AccountButton__icon" />
      </Button>
      <Menu
        id="account-button-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        MenuListProps={{ className: 'AccountButton__menu' }}
        PaperProps={{ className: 'AccountButton__paper' }}
      >
        <MenuItem className="AccountButton__menuItem" onClick={openDepositModal}>
          Deposit
        </MenuItem>
        <MenuItem className="AccountButton__menuItem" onClick={openWithdrawModal}>
          Withdraw
        </MenuItem>
        <MenuItem className="AccountButton__menuItem" onClick={openLinkAccountModal}>
          Link AAA Account
        </MenuItem>
        <MenuItem className="AccountButton__menuItem" onClick={logout}>
          Log out
        </MenuItem>
      </Menu>
      <DepositModal isOpen={isDepositModalOpen} onClose={closeDepositModal} />
      <AppHeaderWithdraw isOpen={isWithdrawModalOpen} onClose={closeWithdrawModal} />
      <AppHeaderLinkAaaAccountModal isOpen={isLinkAccountModalOpen} onClose={closeLinkAccountModal} />
    </>
  );
};
