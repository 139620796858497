import { PropsWithChildren, useCallback, useContext } from 'react';
import { WsContextProvider } from 'common/contexts/WsContext';
import { createWsContext, useHandleWsMessage } from '@cometph/frontend-core/contexts';
import { AppConfig } from '@cometph/frontend-core/helpers';
import {
  handleWsResult,
  isWsAccountValueMessage,
  isWsPortfolioStocksResult,
  mapWsAccountValueMessage,
  mapWsPortfolioStock,
  useWsSubscribe,
  WsMethod,
} from '@cometph/frontend-core/api';
import { useIsAppReady } from 'common/store/appReducer';
import { PortfolioResolution } from 'common/store/portfolioReducer/portfolioReducer.types';
import { updateAccountValue, updatePortfolioStocks } from 'common/store/portfolioReducer/portfolioReducer';
import { useAppDispatch } from 'common/store/store';

const context = createWsContext();

export const usePortfolioWsContext = () => useContext(context);

export const useHandlePortfolioWsMessage = useHandleWsMessage.bind(null, usePortfolioWsContext);

export const PortfolioWsContextProvider = ({ children }: PropsWithChildren) => {
  return (
    <WsContextProvider context={context} url={AppConfig.PORTFOLIO_WS_URL}>
      {children}
    </WsContextProvider>
  );
};

export const usePortfolioWs = () => {
  const isReady = useIsAppReady();
  const dispatch = useAppDispatch();
  useWsSubscribe(usePortfolioWsContext(), WsMethod.AccountValue, {
    body: { period: PortfolioResolution.Day },
    isReady,
  });

  useHandlePortfolioWsMessage(
    'Portfolio',
    useCallback(
      (data) => {
        handleWsResult(
          isWsPortfolioStocksResult,
          (data) => {
            dispatch(updatePortfolioStocks(data));
          },
          mapWsPortfolioStock
        )(data);

        handleWsResult(
          isWsAccountValueMessage,
          (data) => {
            dispatch(updateAccountValue(data));
          },
          mapWsAccountValueMessage
        )(data);
      },
      [dispatch]
    )
  );
};
