import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { CometModal } from 'components/CometModal';
import styled from 'styled-components';
import { styledColor, styledSpace } from 'styles/mixins';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { IconRadioActive } from 'assets/icons/IconRadioActive';
import { IconRadioInactive } from 'assets/icons/IconRadioInactive';
import { Button } from 'components/Button/Button';
import { TextField } from 'components/Field/TextField/TextField';
import { number, object } from 'yup';
import { formatPriceWithCurrency, requiredError } from '@cometph/frontend-core/helpers';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { api } from 'api/api';
import { SidebarSelect } from 'components/SidebarSelect';
import { DepositModalOtherOptions } from 'components/DepositModal/DepositModalOtherOptions';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

enum DepositOption {
  Instant = 'Instant',
  Other = 'Other',
}

enum DepositStage {
  SelectOption,
  InstantTransferAmount,
  OtherMethods,
}

export enum PortfolioDepositModalOtherOption {
  BDO = 'BDO',
  Metrobank = 'Metrobank',
  Unionbank = 'Unionbank',
  BPI = 'BPI',
  OFWRemittance = 'OFW Remittance',
}

const otherOptions = Object.values(PortfolioDepositModalOtherOption).map((value) => ({ value }));

type RadioOptionProps = {
  value: DepositOption;
  label: string;
  currentFormValue: DepositOption | null;
};

const RadioOption = memo(function RadioOption({ value, currentFormValue, label }: RadioOptionProps) {
  const radioRef = useRef<HTMLButtonElement>(null);
  return (
    <RadioOptionWrapper isActive={currentFormValue === value} onClick={() => radioRef.current?.click()}>
      <FormControlLabel
        control={<Radio ref={radioRef} checkedIcon={<IconRadioActive />} icon={<IconRadioInactive />} />}
        value={value}
        label={label}
      />
    </RadioOptionWrapper>
  );
});

type Schema = {
  amount: number | null;
};

const schema = object().shape({
  amount: number().required(requiredError).typeError(requiredError),
});

export const DepositModal = memo(function DepositModal({ isOpen, onClose }: Props) {
  const [depositStage, setDepositStage] = useState(DepositStage.SelectOption);
  const [depositOption, setDepositOption] = useState<DepositOption | null>(null);
  const [isSubmittingAmount, setIsSubmittingAmount] = useState(false);
  const [currentOtherOption, setCurrentOtherOption] = useState(PortfolioDepositModalOtherOption.BDO);
  const form = useForm<Schema>({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setIsSubmittingAmount(false);
        setDepositStage(DepositStage.SelectOption);
        setDepositOption(null);
        setCurrentOtherOption(PortfolioDepositModalOtherOption.BDO);
      }, 200); // wait for the modal closing animation to finish
    }
  }, [isOpen]);

  const handleDepositOptionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDepositOption(event.currentTarget.value as DepositOption);
  }, []);

  const submitDepositOption = useCallback(() => {
    if (!depositOption) return;
    setDepositStage(depositOption === DepositOption.Other ? DepositStage.OtherMethods : DepositStage.InstantTransferAmount);
  }, [depositOption]);

  const submitTransferAmount = useCallback((values: Schema) => {
    setIsSubmittingAmount(true);
    api.createDepositTransaction(values.amount!).then(({ redirect_uri }) => {
      location.href = redirect_uri;
    });
  }, []);

  const depositAmountNumber = Number(form.watch('amount'));
  const formattedDepositAmount = depositAmountNumber ? formatPriceWithCurrency(depositAmountNumber) : '';
  const isSubmitting = form.formState.isSubmitting || isSubmittingAmount;

  return (
    <CometModal isOpen={isOpen} onClose={onClose}>
      {depositStage === DepositStage.SelectOption && (
        <>
          <Title>Deposit</Title>
          <Description>Deposit funds via instant transfer or other methods</Description>
          <StyledRadioGroup value={depositOption} onChange={handleDepositOptionChange}>
            <RadioOption currentFormValue={depositOption} value={DepositOption.Instant} label="Instant Transfer" />
            <RadioOption currentFormValue={depositOption} value={DepositOption.Other} label="Other Methods" />
          </StyledRadioGroup>
          <SubmitButton buttonStyle="primary" disabled={!depositOption} onClick={submitDepositOption}>
            Next
          </SubmitButton>
        </>
      )}
      {depositStage === DepositStage.InstantTransferAmount && (
        <>
          <Title>Deposit via Instant Transfer</Title>
          <Description>
            Deposit funds instantly to your account via Brankas.
            <br /> Just enter the amount below to get started.
          </Description>
          <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(submitTransferAmount)}>
              <TextField
                name="amount"
                label="Enter amount"
                type="number"
                startElement={<AmountCurrencySign>₱</AmountCurrencySign>}
                readOnly={isSubmitting}
              />
              <SubmitButton buttonStyle="primary" disabled={!depositOption} type="submit" loading={isSubmitting}>
                Deposit {formattedDepositAmount}
              </SubmitButton>
            </Form>
          </FormProvider>
        </>
      )}
      {depositStage === DepositStage.OtherMethods && (
        <>
          <Title>Deposit via Other Methods</Title>
          <Description>Deposits may take up to 2 business days to complete</Description>
          <OtherOptionsWrapper>
            <SidebarSelect options={otherOptions} value={currentOtherOption} onSelect={setCurrentOtherOption} />
            <DepositModalOtherOptions option={currentOtherOption} />
          </OtherOptionsWrapper>
        </>
      )}
    </CometModal>
  );
});

const Title = styled.h1`
  font-size: 24px;
  line-height: ${styledSpace(8)};
  color: ${styledColor('text')};
  font-weight: 700;
  margin: 0 0 ${styledSpace(1)};
`;

const Description = styled.div`
  font-size: 14px;
  line-height: ${styledSpace(5)};
  margin-bottom: ${styledSpace(6)};
  color: ${styledColor('textDark')};
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
  gap: ${styledSpace(4)};
`;

const RadioOptionWrapper = styled.button<{ isActive: boolean }>`
  border-style: solid;
  border-width: 2px;
  border-color: ${(props) => (props.isActive ? styledColor('secondary')(props) : styledColor('border')(props))};
  background-color: ${(props) => (props.isActive ? styledColor('secondary')(props) + '11' : 'transparent')};
  padding: 22px;
  border-radius: 8px;
  cursor: pointer;
  text-align: left;
`;

const AmountCurrencySign = styled.div`
  font-size: 16px;
  line-height: ${styledSpace(6)};
  color: ${styledColor('textDark')};
`;

const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: ${styledSpace(6)};
  padding: ${styledSpace(2)} ${styledSpace(4)};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const OtherOptionsWrapper = styled.div`
  display: flex;
  gap: ${styledSpace(6)};
`;
