import React, { memo, useCallback, useEffect, useState } from 'react';
import { CometModal } from 'components/CometModal';
import styled, { css } from 'styled-components';
import { buttonNoStyle, styledColor, styledSpace, textRegular } from 'styles/mixins';
import { useCurrentAccountValue } from 'common/store/portfolioReducer/portfolioReducer';
import { formatPriceWithCurrency, hasMessage, requiredError } from '@cometph/frontend-core/helpers';
import { number, object } from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from 'components/Field/TextField/TextField';
import { Button } from 'components/Button/Button';
import { api } from 'api/api';
import { useSnackbar } from 'notistack';
import { useGetUserInfoQuery } from 'common/store/userApi';
import { AppHeaderWithdrawBankDetails } from 'components/AppHeader/components/Withdraw/AppHeaderWithdrawBankDetails';
import { ArrowBack } from '@mui/icons-material';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

enum Stage {
  Initial,
  BankDetails,
  Confirm,
}

type Schema = {
  amount: number | null;
};

const schema = object().shape({
  amount: number().required(requiredError).typeError(requiredError),
});

export const AppHeaderWithdraw = memo(function AppHeaderWithdraw({ isOpen, onClose }: Props) {
  const accountValue = useCurrentAccountValue();
  const [stage, setStage] = useState(Stage.Initial);
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const form = useForm<Schema>({ resolver: yupResolver(schema) });
  const { enqueueSnackbar } = useSnackbar();
  const { data: { billingInfo } = {} } = useGetUserInfoQuery();
  const resetForm = form.reset;

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setStage(Stage.Initial);
        setIsWithdrawing(false);
        setWithdrawalAmount(0);
        resetForm();
      }, 200); // wait for the modal to close
    }
  }, [isOpen, resetForm]);

  const withdrawSubmit = useCallback((values: Schema) => {
    if (!values.amount) return;

    setWithdrawalAmount(values.amount);
    setStage(Stage.Confirm);
  }, []);

  const withdrawConfirm = useCallback(async () => {
    try {
      setIsWithdrawing(true);
      await api.createWithdrawalTransaction(withdrawalAmount);
      enqueueSnackbar(formatPriceWithCurrency(withdrawalAmount, 2) + ' have been successfully withdrawn from your account.', {
        variant: 'success',
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
      });
      onClose();
    } catch (e) {
      setIsWithdrawing(false);
      if (hasMessage(e)) {
        enqueueSnackbar(e.message, {
          variant: 'error',
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
      }
    }
  }, [enqueueSnackbar, onClose, withdrawalAmount]);

  const withdrawAmountNumber = Number(form.watch('amount'));
  const formattedWithdrawAmount = withdrawAmountNumber ? formatPriceWithCurrency(withdrawAmountNumber) : '';

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {stage === Stage.Initial && (
        <>
          <Title>Withdraw</Title>
          <Description>Seamlessly transfer funds from your wallet to your nominated destination account.</Description>
          <BalanceWrapper>
            <BalanceLabel>Available balance:</BalanceLabel>
            <BalanceValue>{accountValue && formatPriceWithCurrency(accountValue.cashBalance, 2)}</BalanceValue>
          </BalanceWrapper>
          <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(withdrawSubmit)}>
              <TextField name="amount" label="Enter amount" type="number" startElement={<AmountCurrencySign>₱</AmountCurrencySign>} />
              <BillingInfoHeaderWrapper>
                <BillingInfoTitle>Destination account</BillingInfoTitle>
                <BillingInfoEditButton onClick={() => setStage(Stage.BankDetails)}>Edit details</BillingInfoEditButton>
              </BillingInfoHeaderWrapper>
              <InfoWrapper>
                <InfoLineWrapper>
                  <InfoLabel>Bank name</InfoLabel>
                  <InfoValue>{billingInfo?.bankName}</InfoValue>
                </InfoLineWrapper>
                <InfoLineWrapper>
                  <InfoLabel>Account number</InfoLabel>
                  <InfoValue>{billingInfo?.accountNumber}</InfoValue>
                </InfoLineWrapper>
                <InfoLineWrapper>
                  <InfoLabel>Account name</InfoLabel>
                  <InfoValue>{billingInfo?.accountName}</InfoValue>
                </InfoLineWrapper>
              </InfoWrapper>
              <SubmitButton buttonStyle="primary" disabled={!withdrawAmountNumber} type="submit">
                Withdraw {formattedWithdrawAmount}
              </SubmitButton>
            </Form>
          </FormProvider>
        </>
      )}
      {stage === Stage.Confirm && (
        <>
          <Title>Confirm Withdrawal</Title>
          <Description>Please review all details carefully. Changes cannot be made after confirming this withdrawal.</Description>
          <InfoWrapper>
            <ConfirmLineWrapper>
              <InfoLabel>Amount to be withdrawn</InfoLabel>
              <InfoValue>{formatPriceWithCurrency(withdrawalAmount, 2)}</InfoValue>
            </ConfirmLineWrapper>
            <ConfirmLineWrapper>
              <InfoLabel>Bank name</InfoLabel>
              <InfoValue>{billingInfo?.bankName}</InfoValue>
            </ConfirmLineWrapper>
            <ConfirmLineWrapper>
              <InfoLabel>Account number</InfoLabel>
              <InfoValue>{billingInfo?.accountNumber}</InfoValue>
            </ConfirmLineWrapper>
            <ConfirmLineWrapper>
              <InfoLabel>Account name</InfoLabel>
              <InfoValue>{billingInfo?.accountName}</InfoValue>
            </ConfirmLineWrapper>
          </InfoWrapper>
          <SubmitButton buttonStyle="primary" onClick={withdrawConfirm} loading={isWithdrawing}>
            Confirm
          </SubmitButton>
        </>
      )}
      {stage === Stage.BankDetails && (
        <>
          <Title>
            <BackButton onClick={() => setStage(Stage.Initial)}>
              <ArrowBack />
            </BackButton>{' '}
            Edit bank details
          </Title>
          <Description>Ensure your bank details are accurate to prevent delays or issues with your withdrawal.</Description>
          <AppHeaderWithdrawBankDetails />
        </>
      )}
    </Modal>
  );
});

const Modal = styled(CometModal)`
  max-width: ${styledSpace(106)};
`;

const Title = styled.h1`
  font-size: 24px;
  line-height: ${styledSpace(8)};
  color: ${styledColor('text')};
  font-weight: 700;
  margin: 0 0 ${styledSpace(1)};
  display: flex;
  align-items: center;
  gap: ${styledSpace(2)};
`;

const Description = styled.div`
  font-size: 14px;
  line-height: ${styledSpace(5)};
  margin-bottom: ${styledSpace(6)};
  color: ${styledColor('textDark')};
`;

const BalanceWrapper = styled.div`
  display: flex;
  gap: ${styledSpace(2)};
  margin-bottom: ${styledSpace(3)};
`;

const BalanceLabel = styled.div`
  ${textRegular};
  font-weight: 400;
  color: ${styledColor('textDark')};
`;

const BalanceValue = styled.div`
  ${textRegular};
  font-weight: 700;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const AmountCurrencySign = styled.div`
  font-size: 16px;
  line-height: ${styledSpace(6)};
  color: ${styledColor('textDark')};
`;

const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: ${styledSpace(6)};
  padding: ${styledSpace(2)} ${styledSpace(4)};
`;

const infoText = css`
  font-size: 12px;
  line-height: ${styledSpace(5)};
`;

const BillingInfoHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${styledSpace(2)};
`;

const BillingInfoTitle = styled.div`
  ${textRegular};
`;

const BillingInfoEditButton = styled.button`
  ${buttonNoStyle};
  ${textRegular};
  text-align: right;
  color: ${styledColor('primary')};
  font-weight: 500;
`;

const InfoLabel = styled.div`
  ${infoText};
  color: ${styledColor('textDark')};
`;

const InfoValue = styled.div`
  ${infoText};
  color: ${styledColor('text')};
  font-weight: 700;
`;

const InfoWrapper = styled.div`
  background-color: ${styledColor('backgroundDark')};
  padding: ${styledSpace(3)};
  border-radius: 8px;
`;

const InfoLineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${styledSpace(1)} 0;
`;

const ConfirmLineWrapper = styled(InfoLineWrapper)`
  box-shadow: 0 1px ${styledColor('borderDarker')};
  padding: ${styledSpace(2)} 0;

  &:last-child {
    box-shadow: none;
  }
`;

const BackButton = styled.button`
  ${buttonNoStyle};

  svg {
    fill: ${styledColor('secondary')};
  }
`;
