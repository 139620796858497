import { configureStore } from '@reduxjs/toolkit';
import { rootReducer, RootState } from './rootReducer';
import { useDispatch } from 'react-redux';
import { activityApi } from 'common/store/activityApi';
import { userApi } from 'common/store/userApi';

export const configureAppStore = (preloadedState?: Partial<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (gDM) => gDM().concat([activityApi.middleware, userApi.middleware]),
    preloadedState,
  });

export const store = configureAppStore();

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
