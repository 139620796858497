import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { defaultRetryCondition, fetchBaseQuery, prepareApiHeaders } from 'common/store/reduxHelpers';
import { AppConfig, applyQueryResult } from '@cometph/frontend-core/helpers';
import { KEEP_UNUSED_DATA_FOREVER } from 'common/constants';
import { WsBillingInfo } from '@cometph/frontend-core/api/types/WsTypes/WsBillingInfo';
import { BillingInfo, UserInfo, WsUserInfo } from '@cometph/frontend-core/api';
import { HttpMethod } from '@cometph/frontend-core/types';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: `${AppConfig.BASE_API_URL}user-info`,
      prepareHeaders: prepareApiHeaders,
    }),
    { retryCondition: defaultRetryCondition }
  ),
  endpoints: (builder) => ({
    getUserInfo: builder.query<UserInfo, void>({
      query: () => ({ url: '' }),
      transformResponse: ({ user_id, bank_details_info }: WsUserInfo) => ({
        id: user_id,
        billingInfo: {
          bankName: bank_details_info.bank_name,
          accountName: bank_details_info.account_name,
          accountNumber: bank_details_info.account_number,
        },
      }),
      keepUnusedDataFor: KEEP_UNUSED_DATA_FOREVER,
    }),
    updateBillingInfo: builder.mutation<WsBillingInfo, BillingInfo>({
      query: (billingInfo: BillingInfo) => ({
        url: `bank_details_info`,
        method: HttpMethod.PUT,
        body: {
          bank_name: billingInfo.bankName,
          account_number: billingInfo.accountNumber,
          account_name: billingInfo.accountName,
        },
      }),
      onQueryStarted: async (billingInfo, { dispatch, queryFulfilled }) => {
        await applyQueryResult(
          queryFulfilled,
          dispatch(
            userApi.util.updateQueryData('getUserInfo', undefined, (userInfo) => {
              userInfo.billingInfo = billingInfo;
            })
          )
        );
      },
    }),
  }),
});

export const { useGetUserInfoQuery, useUpdateBillingInfoMutation } = userApi;
