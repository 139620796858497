import React, { FC, memo, useMemo } from 'react';
import { usePortfolioStocks, usePortfolioStocksTotals } from 'common/store/portfolioReducer/portfolioReducer';
import { PortfolioStock } from '@cometph/frontend-core/api';
import styled, { createGlobalStyle, css } from 'styled-components';
import { typography } from 'styles/typography';
import { darkColors } from 'styles/theme';
import { styledColor, styledSpace } from 'styles/mixins';
import { StockLogo } from 'components/StockLogo';
import {
  formatPercent,
  formatPercentWithoutSign,
  formatPriceWithCurrency,
  getNumberSign,
  numberWithCommas,
} from '@cometph/frontend-core/helpers';
import { IconStockArrow } from 'assets/icons/IconStockArrow';
import styles from 'modules/dashboard/components/Stock/components/Header/DashboardStockHeader.module.scss';
import { useIsAppReady } from 'common/store/appReducer';
import { IconCoins } from 'assets/icons/IconCoins';
import { PortfolioWrapper } from 'modules/portfolio/components/PortfolioWrapper';
import { ScrollableWrapper } from 'components/ScrollableWrapper';

export type PortfolioTableStock = PortfolioStock & {
  marketValue: number;
  totalCost: number;
};

export const PortfolioStocks = memo(function PortfolioStocks() {
  const stocks = usePortfolioStocks();
  const totals = usePortfolioStocksTotals();
  const isReady = useIsAppReady();

  const { tableStocks, totalSum } = useMemo(() => {
    const sum = {
      totalValue: 0,
      totalCost: 0,
    };
    const mappedStocks = stocks.map((stock): PortfolioTableStock => {
      const marketValue = stock.price * stock.quantity;
      const totalCost = stock.cost * stock.quantity;

      sum.totalValue += marketValue;
      sum.totalCost += totalCost;

      return {
        ...stock,
        marketValue,
        totalCost,
      };
    });

    return {
      totalSum: sum,
      tableStocks: mappedStocks,
    };
  }, [stocks]);

  return (
    <>
      <GlobalScrollContainerStyle />
      <Wrapper rows={stocks} isLoading={!isReady} emptyIcon={<IconCoins />} emptyText="There are no stock holdings to show.">
        <Container aria-label="portfolio stocks table">
          <TableRow>
            <HeaderCell>stock</HeaderCell>
            <HeaderCell $align="right">quantity</HeaderCell>
            <HeaderCell $align="right">avg. cost</HeaderCell>
            <HeaderCell $align="right">market price</HeaderCell>
            <HeaderCell $align="right">market value</HeaderCell>
            <HeaderCell $align="right">total cost</HeaderCell>
            <HeaderCell $align="right">day gain</HeaderCell>
            <HeaderCell $align="right">total gain</HeaderCell>
          </TableRow>
          <ScrollContainer containerClassName={ScrollContainer.styledComponentId}>
            {tableStocks.map((stock) => (
              <TableRow key={stock.symbol}>
                <Cell>
                  <StockLogo symbol={stock.symbol} url={stock.logo} size={9} />
                  <StockName>{stock.symbol}</StockName>
                </Cell>
                <Cell $align="right">{numberWithCommas(stock.quantity)}</Cell>
                <Cell $align="right">{formatPriceWithCurrency(stock.cost)}</Cell>
                <Cell $align="right">{formatPriceWithCurrency(stock.price)}</Cell>
                <Cell $align="right">{formatPriceWithCurrency(stock.marketValue)}</Cell>
                <Cell $align="right">{formatPriceWithCurrency(stock.totalCost)}</Cell>
                <Cell $align="right">
                  {getNumberSign(stock.dayGain)} {formatPriceWithCurrency(stock.dayGain)}
                  <ChangeWrapper>
                    <ChangeArrow value={stock.dayGain} className={styles.arrowIcon} />
                    <ChangeText value={stock.dayGain}>{formatPercentWithoutSign(stock.dayGainPercent)}</ChangeText>
                  </ChangeWrapper>
                </Cell>
                <Cell $align="right">
                  {getNumberSign(stock.totalGain)} {formatPriceWithCurrency(stock.totalGain)}
                  <ChangeWrapper>
                    <ChangeArrow value={stock.totalGain} className={styles.arrowIcon} />
                    <ChangeText value={stock.totalGain}>{formatPercentWithoutSign(stock.totalGainPercent)}</ChangeText>
                  </ChangeWrapper>
                </Cell>
              </TableRow>
            ))}
          </ScrollContainer>

          <FooterRow>
            <FooterCell />
            <FooterCell />
            <FooterCell />
            <FooterCell />
            <FooterCell>{formatPriceWithCurrency(totalSum.totalValue)}</FooterCell>
            <FooterCell>{formatPriceWithCurrency(totalSum.totalCost)}</FooterCell>
            <FooterCell>
              {formatPriceWithCurrency(totals.totalDayGain)}
              <ChangeWrapper>
                <ChangeArrow value={totals.totalDayGain} className={styles.arrowIcon} />
                <ChangeText value={totals.totalDayGain}>{formatPercent(totals.totalDayGainPercent)}</ChangeText>
              </ChangeWrapper>
            </FooterCell>
            <FooterCell>
              {formatPriceWithCurrency(totals.totalGain)}
              <ChangeWrapper>
                <ChangeArrow value={totals.totalGain} className={styles.arrowIcon} />
                <ChangeText value={totals.totalGain}>{formatPercent(totals.totalGainPercent)}</ChangeText>
              </ChangeWrapper>
            </FooterCell>
          </FooterRow>
        </Container>
      </Wrapper>
    </>
  );
});

const containerCss = css`
  width: 100%;
`;

const Container = styled.div`
  margin-top: ${styledSpace(3)};
  ${containerCss};
`;

const Wrapper = styled(PortfolioWrapper)`
  margin-top: ${styledSpace(20)};
  ${containerCss}
`;

const ScrollContainer = styled(ScrollableWrapper)`
  max-height: ${styledSpace(69)};
  height: fit-content;
  position: relative !important;
`;

const GlobalScrollContainerStyle = createGlobalStyle`
    ${ScrollContainer}.scrollable-view {
        position: relative !important;
        overflow: visible !important;
        width: auto !important;
        height: auto !important;
    }
` as unknown as FC;

const Cell = styled.div<{ $align?: string }>`
  && {
    flex: 1 1 0;
    ${typography.textSmall};
    font-weight: 500;
    text-transform: uppercase;
    border: none;
    padding: ${styledSpace(1)} ${styledSpace(2)};
    vertical-align: middle;
    text-align: ${(props) => props?.$align ?? 'left'};

    & > * {
      vertical-align: middle;
    }
  }
`;

const HeaderCell = styled(Cell)`
  && {
    color: ${darkColors.textDarker};
    padding: ${styledSpace(2)};
  }
`;

const FooterCell = styled(Cell)`
  && {
    font-weight: 700;
    text-align: right;
    padding: ${styledSpace(3)} ${styledSpace(2)};
    vertical-align: top;

    & > * {
      vertical-align: top;
    }
  }
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  box-shadow: inset 0 -2px ${darkColors.borderDark};

  & > ${Cell}:first-child, & > ${Cell}:nth-last-child(-n+4) {
    flex: 1.5 1.5 0;
  }
`;

const FooterRow = styled(TableRow)`
  box-shadow: inset 0 2px ${darkColors.borderDark};
`;

const StockName = styled.span`
  display: inline-block;
  color: ${darkColors.textDarker};
  font-weight: 700;
`;

const ChangeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${styledSpace(1)};
`;

const ChangeArrow = styled(IconStockArrow)`
  font-size: ${styledSpace(2.5)} !important;
`;

const ChangeText = styled.span<{ value: number }>`
  color: ${(props) =>
    props.value > 0 ? styledColor('success')(props) : props.value < 0 ? styledColor('danger')(props) : styledColor('textDarker')(props)};
`;
