import React, { memo, PropsWithChildren } from 'react';
import Fade from '@mui/material/Fade';
import { IconClose } from 'assets/icons/IconClose';
import { Modal } from '@mui/material';
import styled, { useTheme } from 'styled-components';
import { buttonNoStyle, styledSpace } from 'styles/mixins';

type Props = PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
  className?: string;
}>;

export const CometModal = memo(function CometModal({ isOpen, onClose, className, children }: Props) {
  const theme = useTheme();
  return (
    <Modal className="comet-modal" open={isOpen} onClose={onClose}>
      <Fade in={isOpen}>
        <Container className={className}>
          <CloseButton onClick={onClose}>
            <IconClose stroke={theme.colors.secondaryLight} />
          </CloseButton>
          <Content>{children}</Content>
        </Container>
      </Fade>
    </Modal>
  );
});

const Container = styled.div`
  padding: 1px;
  background: linear-gradient(-140deg, #ab9ff4 0%, #2a179b 80%);
  width: fit-content;
  border-radius: 8px;
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  transform: translate(-50%, -50%);
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Content = styled.div`
  background: linear-gradient(40.21deg, #171d31 26.1%, #201d2c 79.42%);
  box-shadow: inset 2px 2px 100px rgba(66, 66, 66, 0.1);
  padding: ${styledSpace(8)};
  display: flex;
  flex-direction: column;
  min-width: ${styledSpace(100)};
  border-radius: 8px;
  flex: 1 1;
  overflow: hidden;
  box-sizing: border-box;
`;

const CloseButton = styled.button`
  ${buttonNoStyle};
  position: absolute;
  top: ${styledSpace(4)};
  right: ${styledSpace(4)};
  cursor: pointer;

  svg {
    font-size: ${styledSpace(6)};
  }
`;
